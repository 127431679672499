<template>
    <div>
        <div class="overflow-x-hidden grid sm:grid-cols-2 items-center gap-10 items-center px-5 lg:px-0">
            <!-- Image Mobile -->
            <div class="sm:hidden">
                <img class="h-28 sm:h-42 mx-auto" :src="data.img">
            </div>
            <div class="hidden sm:block" v-if="side == 'right'">
                <img :src="data.img">
            </div>
            <div :data-aos="side == 'left' ? 'fade-left' : 'fade-right'">
                <h2 id="title" class="text-3xl font-bold text-black" v-html="data.titre"></h2>
                <div class="mt-4 font-light text-lg" v-html="data.detail"></div>
                </div>
            <div class="hidden sm:block" v-if="side == 'left'">
                <img :src="data.img">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        side: {default: 'left'},
        data: {required: true}
    }
}
</script>

<style lang="scss" scoped>

</style>
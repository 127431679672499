<template>
    <div>
        <div class="bg-slate-100 py-20" :class="customClass">
            <div class="text-center">
                <div class="text-4xl font-black">{{ $t('demo.title') }}</div>
                <p class="font-extralight text-xl mt-2">{{ $t('demo.subtitle') }}</p>
                <div class="mt-6" data-aos="zoom-in" data-aos-delay="150">
                    <a href="/contact" class="mr-4 bg-emerald-600 hover:bg-emerald-600 duration-200 text-white px-5 py-3 rounded-full">
                        <i class="fas fa-eye mr-1"></i> {{ $t('demo.button') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customClass: {}
    }
}
</script>
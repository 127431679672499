<template>
    <div>
        <div id="statsNumbers" class="text-white" :class="customClass">
            <div v-if="displayStats" class="md:grid grid-cols-2 xl:grid-cols-4 gap-10 container mx-auto">
                <div class="p-5 text-center mb-20 md:mb-0">
                    <div class="font-black text-6xl">
                        + <number
                        :from="0"
                        :to="25"
                        :duration="1"
                        :delay="0.2"
                        easing="Power1.easeOut"
                        />
                    </div>
                    <div class="font-extralight text-xl mt-2">{{ $t('stats.annee') }}</div>
                </div>
                <div class="p-5 text-center mb-20 md:mb-0">
                    <div class="font-black text-6xl">
                        <number
                        :from="1"
                        :to="4000000"
                        :duration="1"
                        :delay="0.2"
                        easing="Power1.easeOut"
                        />
                    </div>
                    <div class="font-extralight text-xl mt-2">{{ $t('stats.dossiers') }}</div>
                </div>
                <div class="p-5 text-center mb-20 md:mb-0">
                    <div class="font-black text-6xl">
                        <number
                        :from="400"
                        :to="650"
                        :duration="1"
                        :delay="0.2"
                        easing="Power1.easeOut"
                        />k
                    </div>
                    <div class="font-extralight text-xl mt-2">{{ $t('stats.appareils') }}</div>
                </div>
                <div class="p-5 text-center mb-20 md:mb-0">
                    <div class="font-black text-6xl">
                        <number
                        :from="1000"
                        :to="17000"
                        :duration="1"
                        :delay="0.2"
                        easing="Power1.easeOut"
                        />
                    </div>
                    <div class="font-extralight text-xl mt-2">{{ $t('stats.acteurs') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customClass: {}
    },
    data() {
        return {
            displayStats: false
        }
    },

    mounted() {
        let statsEl = document.getElementById('statsNumbers')
        window.addEventListener("scroll", () => {
            if (statsEl.offsetTop - window.top.scrollY - screen.height + 200 < 0) {
                this.displayStats = true
            }
        })
    }
}
</script>

/* ----------
    Lib
-----------*/

function getObj(key, arrayItems) {
    return arrayItems.find((item) => item.id == key)
}


var LangTool = {
    getObj,
}

export default LangTool

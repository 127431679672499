<template>
    <div class="home">
        <sav-template>
            <div class="2xl:w-2/3 lg:container mx-auto">
                <div class="lg:grid grid-cols-2 items-center">
                    <div class="px-4 lg:px-0">
                        <div class="text-center lg:text-left lg:flex justify-between grow items-center">
                            <div class="lg:pr-10 h-screen flex items-center">
                                <div>
                                    <h1 class="text-5xl font-black" data-aos="fade-down" data-aos-delay="50" v-html="$t('home.banniere.titre')"></h1>
                                    <h2 class="mt-5 text-2xl font-extralight" data-aos="fade-down" data-aos-delay="150" v-html="$t('home.banniere.sous_titre')"></h2>
                                    <h3 class="font-thin opacity-60 text-lg mt-5" data-aos="fade-down" data-aos-delay="300" v-html="$t('home.banniere.sous_titre2')"></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pl-5 w-[80%] mt-10 mx-auto overflow-x-hidden">
                        <img loading="lazy" class="hidden lg:block rounded-xl" src="/img/home.png" data-aos="fade-left" data-aos-delay="450" alt="demo districloud">
                        <!-- <object type="image/svg+xml" data="/img loading="lazy"/savlog-home.svg"></object> -->
                    </div>
                </div>
                <div class="flex justify-center">
                    <div :class="bounceAnimation ? 'animate__bounce' : ''" class="hidden md:block pb-10 -mt-20 text-xl animate__animated">
                        <button class="cursor-pointer" @click="scrollToClients">
                            <i class="fas fa-chevron-down"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div id="clients-list" class="border-y pt-8 pb-5">
                <clients-list />
            </div>

            <div class="mt-20 pb-10">
                <div class="2xl:w-2/3 lg:container mx-auto p-10 py-16 border border-emerald-100 rounded-3xl bg-emerald-50 text-emerald-900">
                    <h2 class="text-4xl font-bold" v-html="$t('home.resume.titre')"></h2>
                    <h2 class="text-xl font-extralight mt-3" v-html="$t('home.resume.sous_titre')"></h2>
                    <p class="border-t pt-6 my-6 font-light" v-html="$t('home.resume.intro')"></p>
                    <div class="lg:grid grid-cols-2">
                    
                        <div v-for="(item, itemIndex) in $t('home.resume.items')" :key="'item-' + itemIndex" class="font-semibold mb-4" data-aos="fade-up" data-aos-delay="110">
                            <i class="fas mr-1" :class="item.icon"></i> <span v-html="item.text"></span>
                        </div>
                    </div>
                </div>
            </div>


            <div class="mt-20">
                <div class="text-center px-4 md:px-0">
                    <h4 class="text-4xl font-bold">{{$t('home.acteurs.title')}}</h4>
                    <h5 class="text-xl font-extralight mt-1">{{$t('home.acteurs.subtitle')}}</h5>
                </div>

                <div class="2xl:w-2/3 lg:container mx-auto px-4 lg:px-0">
                    <!-- Selfcare -->
                    <div class="my-20 lg:mb-0">
                        <div class="md:grid grid-cols-2 gap-10 items-center">
                            <div class="hidden md:block">
                                <img loading="lazy" class="w-3/4" data-aos="fade-right" data-aos-offset="200" src="/img/mobile.png" alt="consommateur">
                            </div>
                            <div class="">
                                <div class="text-emerald-600 text-sm font-light mb-1">SelfCare</div>
                                <h2 class="text-3xl font-light"><b class="font-bold text-4xl">{{$t('home.acteurs.consommateur.title')}}</b> <span class="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full" v-html="$t('home.acteurs.new')"></span></h2>
                                <p class="font-light text-lg text-justify my-6">
                                    <ul class="ml-4">
                                        <li v-for="(item, itemIndex) in $t('home.acteurs.consommateur.items')" :key="'consommateur-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                    </ul>
                                </p>
                                <!-- <button @click="$router.push({name: 'selfcare'})" class="bg-emerald-600 hover:bg-emerald-700 text-sm font-light duration-200 text-white py-3 px-5 rounded-full md:mt-5 shadow-xl">{{$t('home.acteurs.en_savoir_plus')}} <i class="fas fa-arrow-right ml-1"></i></button> -->
                            </div>
                        </div>
                    </div>

                    <!-- Fournisseur -->
                    <div class="mt-10 mb-20 lg:mb-0">
                        <div class="md:grid grid-cols-2 gap-10 items-center">
                            <div class="md:text-left">
                                <div class="text-emerald-600 text-sm font-light mb-1">Districloud</div>
                                <h2 class="text-3xl font-light"><b class="font-bold text-4xl">{{$t('home.acteurs.fournisseur.title')}}</b></h2>
                                <p class="font-light text-lg text-justify my-6">
                                    <ul class="ml-4">
                                        <li v-for="(item, itemIndex) in $t('home.acteurs.fournisseur.items')" :key="'fournisseur-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                    </ul>
                                </p>
                                <a href="/fournisseur" class="bg-emerald-600 hover:bg-emerald-700 text-sm font-light duration-200 text-white py-3 px-5 rounded-full md:mt-5 shadow-xl">{{$t('home.acteurs.en_savoir_plus')}} <i class="fas fa-arrow-right ml-1"></i></a>
                            </div>
                            <div class="overflow-x-hidden">
                                <img loading="lazy" class="hidden md:block" data-aos="fade-left" data-aos-offset="200" src="/img/export.png" alt="fournisseur">
                            </div>
                        </div>
                    </div>
                    
                    <!-- Magasin -->
                    <div class="my-20 lg:mb-0">
                        <div class="md:grid grid-cols-2 gap-10 items-center">
                            <div class="p-14 hidden md:block">
                                <img loading="lazy" data-aos="fade-right" data-aos-offset="200" src="/img/cart.png" alt="distributeur">
                            </div>
                            <div class="bg-white md:p-10">
                                <div class="text-emerald-600 text-sm font-light mb-1">Districloud</div>
                                <h2 class="text-3xl font-light"><b class="font-bold text-4xl">{{$t('home.acteurs.distributeur.title')}}</b></h2>
                                <p class="font-light text-lg text-justify my-6">
                                    <ul class="ml-4">
                                        <li v-for="(item, itemIndex) in $t('home.acteurs.distributeur.items')" :key="'distributeur-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                    </ul>
                                </p>
                                <a href="/distributeur" class="bg-emerald-600 hover:bg-emerald-700 text-sm font-light duration-200 text-white py-3 px-5 rounded-full md:mt-5 shadow-xl">{{$t('home.acteurs.en_savoir_plus')}} <i class="fas fa-arrow-right ml-1"></i></a>
                            </div>
                        </div>
                    </div>

                    <!-- Station -->
                    <div class="my-20">
                        <div class="md:grid grid-cols-2 gap-10 items-center">
                            <div class="">
                                <div class="text-emerald-600 text-sm font-light mb-1">SavlogSTA</div>
                                <h2 class="text-3xl font-light"><b class="font-bold text-4xl">{{$t('home.acteurs.reparateur.title')}}</b></h2>
                                <p class="font-light text-lg text-justify my-6">
                                    <ul class="ml-4">
                                        <li v-for="(item, itemIndex) in $t('home.acteurs.reparateur.items')" :key="'reparateur-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                    </ul>
                                </p>
                                <a href="/reparateur" class="bg-emerald-600 hover:bg-emerald-700 text-sm font-light duration-200 text-white py-3 px-5 rounded-full md:mt-5 shadow-xl">{{$t('home.acteurs.en_savoir_plus')}} <i class="fas fa-arrow-right ml-1"></i></a>
                            </div>
                            <div class="overflow-x-hidden">
                                <img loading="lazy" class="hidden md:block" data-aos="fade-left" data-aos-offset="200" src="/img/repairer.png" alt="reparateur">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <stat-numbers custom-class="bg-emerald-900 py-16" />


            <div id="temoignages" class="mt-10 2xl:w-2/3 lg:container mx-auto p-2 md:p-10 md:py-16 text-center">
                <div class="lg:w-2/3 mx-auto relative">
                    <h2 class="font-bold text-3xl text-center mb-10" v-html="$t('temoignage_titre')"></h2>
                    <div class="border rounded-xl p-5 px-10">
                        <temoignages>
                            <slide>
                                <slide-avis ref="section-mrbricolage" :data="$langTool.getObj('mrbricolage', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-arb" :data="$langTool.getObj('arb', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-casino" :data="$langTool.getObj('casino', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-gtcompany" :data="$langTool.getObj('gtcompany', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-trax" :data="$langTool.getObj('trax', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-ageps" :data="$langTool.getObj('ageps', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-sologne" :data="$langTool.getObj('sologne', $t('temoignages'))" />
                            </slide>
                            <slide>
                                <slide-avis ref="section-atr" :data="$langTool.getObj('atr', $t('temoignages'))" />
                            </slide>
                        </temoignages>
                    </div>
                </div>
            </div>


            <div class="bg-slate-900 pb-16">
                <div class="2xl:w-2/3 lg:container mx-auto p-10 py-16 text-center">
                    <div class="items-center md:grid grid-cols-3 gap-4 mt-10 overflow-x-hidden">
                        <img loading="lazy" class="h-52 mx-auto mb-10 md:mb-0" src="/img/green_earth.png" data-aos="fade-left" alt="RSE">
                        <div class="text-left text-white col-span-2 text-lg text-center md:text-left font-light">
                            <h2 class="text-4xl font-bold text-white mb-1" v-html="$t('home.rse.titre')"></h2>
                            <h4 class="mb-5 font-thin" v-html="$t('home.rse.sous_titre')"></h4>
                            <ul class="list-disc ml-4">
                                <li v-for="(item, itemIndex) in $t('home.rse.items')" :key="'rse-' + itemIndex" class="mb-1" v-html="item"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div class="2xl:w-2/3 lg:container mx-auto p-10 py-2 mt-10">
                <div class="grid md:grid-cols-2 gap-10 mt-10">
                    <div>
                        <div class="border rounded-xl p-5 py-10">
                            <h2 class="text-2xl font-bold mb-6 text-center" v-html="$t('home.donnees.titre')"></h2>
                            <div class="grid md:grid-cols-2 mt-3">
                                <div class="text-center mb-8 md:mb-0" data-aos="fade-in">
                                    <img loading="lazy" class="h-10 mx-auto my-2" src="/img/rgpd.png" alt="RGPD">
                                    <span class="font-light text-sm" v-html="$t('home.donnees.rgpd')"></span>
                                </div>
                                <div class="text-center" data-aos="fade-in" data-aos-delay="50">
                                    <img loading="lazy" class="h-10 mx-auto my-2" src="/img/norme_iso.png" alt="Hebergement des données en Europe">
                                    <span class="font-light text-sm" v-html="$t('home.donnees.europe')"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="border rounded-xl p-5 py-10">
                            <h2 class="text-2xl font-bold mb-6 text-center" v-html="$t('home.international.titre')"></h2>
                            <div class="grid md:grid-cols-2 gap-10 my-5">
                                <div class="text-center mb-8 md:mb-0" data-aos="fade-in" data-aos-delay="150">
                                    <img loading="lazy" class="h-10 mx-auto my-2" src="/img/translate.png" alt="Traductions">
                                    <span class="font-light text-sm" v-html="$t('home.international.traductions')"></span>
                                </div>
                                <div class="text-center" data-aos="fade-in" data-aos-delay="200">
                                    <img loading="lazy" class="h-10 mx-auto my-2" src="/img/delivery.png" alt="Transport">
                                    <span class="font-light text-sm" v-html="$t('home.international.transport')"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <ask-demo class="md:mt-28" />


            <div class="mt-10">
                <div class="2xl:w-2/3 lg:container mx-auto md:p-10 py-16 text-center">
                    <p class="md:w-2/3 mx-auto text-2xl font-thin" v-html="citation.contenu"></p>
                    <p class="mt-5">Philippe Chouret, <b v-html="citation.statut"></b></p>
                </div>
            </div>

            <div class="mb-10 bg-slate-100 py-10">
                <blog-articles />
            </div>

            <faq :questions="$t('home.questions')"/>

        </sav-template>
    </div>
</template>

<script>
import Temoignages from '../components/Temoignages.vue';
import { Slide } from 'vue-carousel';

export default {
    name: 'Home',
    components: {
        Temoignages,
        Slide
    },
    created () {
        document.title = "Districloud - Logiciel de service après-vente"

        // Mata og
        var link = document.createElement('meta');
        link.setAttribute('property', 'og:url');
        link.content = document.location
        document.getElementsByTagName('head')[0].appendChild(link)

        // Meta description
        link = document.createElement('meta');
        link.setAttribute('name', 'description');
        link.content = 'Districloud: la solution collaborative pour simplifier et optimiser votre service après-vente. Découvrez notre logiciel SaaS spécialisé depuis 1998.'
        document.getElementsByTagName('head')[0].appendChild(link)

        link = document.createElement('meta');
        link.setAttribute('name', 'keywords');
        link.content = "Districloud,Service après vente,Plateforme collaborative,Gestion du service client,Gestion des demandes de SAV,Procédures de SAV,Réparations,Retours produits,Pièces détachées,Données d'analyse,Gestion de la D3E,Call center,Fournisseur,Distributeur,Réparateur,Consommateur,Relation clients,Logistique,Gestion quotidienne,Service inédit,RSE (Responsabilité Sociétale des Entreprises),Transports optimisés,Numérisation des dossiers,Normes RGPD (Règlement général sur la protection des données),Hébergement des données en Europe,Logiciel SaaS,Gestion du SAV depuis 1998"
        document.getElementsByTagName('head')[0].appendChild(link)
    },
    data() {
        return {
            bounceAnimation: false,
        }
    },
    
    computed: {
        citation() {
            return this.$langTool.getObj('philippe_1', this.$t('citations'))
        }
    },

    methods: {
        scrollToClients() {
            var elmntToView = document.getElementById("clients-list");
            elmntToView.scrollIntoView({behavior: "smooth"}); 
        },
    },

    mounted() {
        setTimeout(() => {
            this.bounceAnimation = true
            setInterval(() => {
                this.bounceAnimation = !this.bounceAnimation
            }, 3000)
        }, 3000)
    }
}
</script>
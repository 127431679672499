<template>
    <div class="px-5">
        <h2 class="text-2xl text-center mb-5 font-bold" v-html="$t('blog.articles.titre')"></h2>
        <div class="flex justify-center mb-2">
            <spinner v-if="loading" />
        </div>

        <div class="sm:grid md:grid-cols-2 lg:grid-cols-4 gap-5">
            <div
            v-for="(article, articleIndex) in articles"
            :key="'article-' + articleIndex"
            class="border rounded flex bg-white flex-col justify-between"
            >
                <div>
                    <div
                    class="w-full h-[200px] bg-cover bg-center rounded-t bg-slate-300"
                    :style="'background-image: url(' + (article.img ? article.img : '/img/no_iamge.jpeg') + ');'"
                    ></div>
                    <div class="p-4 pb-2">
                        <h3 class="font-bold">{{ article.title }}</h3>
                        <p class="font-light text-sm">{{ article.summary }}</p>
                    </div>
                </div>
                <div class="px-4 mb-4">
                    <a :href="'/blog/article/' + article.url" class="bg-emerald-600 hover:bg-emerald-700 duration-200 text-white px-4 py-2 rounded-full font-light text-sm">
                        Consulter
                    </a>
                </div>
            </div>
        </div>
        <div class="text-center mt-5">
            <a href="/blog/articles" class="bg-slate-300 text-slate-600 hover:bg-slate-400 duration-200 px-6 py-2 rounded-full font-light">
                Voir plus <i class="fas fa-plus ml-0.5"></i>
            </a>
        </div>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            articles: [],
            loading: false,
        }
    },
    methods: {
        async getArticles() {
            this.loading = true
            let response = await apiService.post('vitrine.article.list', {
                no_user: 1,
                display: 1,
                limit: 4
            })
            this.articles = response.data.posts.post
            this.loading = false
        }
    },
    mounted () {
        this.getArticles();
    },
}
</script>

<style lang="scss" scoped>

</style>
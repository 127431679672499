<template>
    <div>
        <sav-template>
            <div class="xl:w-1/2 lg:container mx-auto mt-28">
                <div v-if="loading">
                    <div class="flex justify-center">
                        <spinner />
                    </div>
                    <div class="mt-5 text-center font-light text-sm">Chargement de l'article</div>
                </div>
                <template v-if="!loading">
                    <div v-if="article">
                        <img v-if="article.img" class="w-full rounded-xl mb-10" :src="article.img" :alt="article.title">
                        <h1 class="font-black text-4xl text-black">{{ article.title }}</h1>
                        <div class="article-reader">
                            <p class="mt-10" v-html="article.content"></p>
                        </div>
                    </div>
                </template>
            </div>
        </sav-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            article: null,

            loading: false,
        }
    },
    methods: {
        async getArticle() {
            this.loading = true
            let response = await apiService.post('vitrine.article.get', {
                url: this.$route.params.slug,
                no_user: 1
            })
            if (response.data.posts.error) {
                this.$toast.error(response.data.posts.error)
                this.$router.push({name: 'home'})
                return
            }
            this.article = response.data.posts.post
            this.loading = false
        }
    },
    mounted() {
        this.getArticle()
    }
}
</script>
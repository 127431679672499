<template>
    <div class="bg-white border rounded-xl">
        <div class="font-bold flex items-center justify-between cursor-pointer py-6 px-4" @click="open = !open">
            <div>{{ question }}</div>
            <div class="text-emerald-600 text-lg pl-3">
                <div v-show="!open">
                    <i class="fas fa-circle-chevron-down"></i>
                </div>
                <div v-show="open">
                    <i class="fas fa-circle-chevron-up"></i>
                </div>
            </div>
        </div>
        <div class="border-t px-6 py-6 font-light" v-if="open">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        question: {},
    },
    data() {
        return {
            open: false
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <sav-template>
            <div class="2xl:w-2/3 lg:container mx-auto pt-10 mt-20">
                <div v-for="(temoignage, temoignageIndex) in $t('temoignages')" class="px-5 lg:px-0 md:grid grid-cols-2 mb-52" :key="'temoignage-' + temoignageIndex">
                    <img class="w-48 mb-5 md:mb-0 md:mx-auto md:sticky top-28" loading="lazy" :src="temoignage.img"  :alt="temoignage.societe">
                    <div>
                        <div class="font-bold text-2xl" v-html="temoignage.author || '-'"></div>
                        <div class="font-bold mb-5" v-html="temoignage.statut"></div>
                        <p v-html="temoignage.contenu"></p>
                        <p class="mt-5 font-thin opacity-80 text-lg" v-html="temoignage.societe_description"></p>
                    </div>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <div class="text-3xl font-black text-center" v-html="$t('faq.titre')"></div>
        <div class="2xl:w-2/3 lg:container mx-auto p-10 rounded-3xl">
            <div class="mb-2" v-for="(question, qIndex) in questions" :key="qIndex">
                <dropdown-question :question="question.question">
                    <span v-html="question.reponse"></span>
                </dropdown-question>
            </div>
        </div>
    </div>
</template>

<script>
import DropdownQuestion from './DropdownQuestion.vue';

export default {
    components: { DropdownQuestion },
    props: {
        questions: {},
    },
}
</script>

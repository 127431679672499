<template>
    <div>
        <sav-template>
            <!-- Header -->
            <div class="container mx-auto px-4 md:px-0 flex items-center justify-center mt-20 pt-10" style="height: 30vh">
                <div class="text-center">
                    <h1 class="text-6xl font-bold">Inscription</h1>
                    <p class="text-3xl mt-4 font-extralight">Création de votre compte</p>
                </div>
            </div>

            <div class="container mx-auto px-4 md:px-0 mb-20">
                <div class="bg-blue-100 text-blue-800 mx-auto p-4 rounded-xl xl:w-1/2 lg:w2/3 mb-5">
                    <div class="font-bold text-lg">Offre: Fournisseur Premium</div>
                    <div class="font-light text-sm">75 € / mois</div>
                </div>
                <div class="bg-slate-100 mx-auto p-4 rounded-xl xl:w-1/2 lg:w2/3">
                    <input-rule v-model="form.raison_sociale" class="mt-5" :check-rules="canCheckInputs" rules="required" type="text" label="Raison sociale" />
                    <input-rule v-model="form.siret" class="mt-5" :check-rules="canCheckInputs" rules="required" type="text" label="SIRET" />
                    <input-rule v-model="form.nom" class="mt-5" :check-rules="canCheckInputs" rules="required" type="text" label="Nom" />
                    <input-rule v-model="form.email" class="mt-5" :check-rules="canCheckInputs" rules="required|email" type="text" label="Email" />
                    <input-rule v-model="form.tel" class="mt-5" :check-rules="canCheckInputs" rules="required|tel" type="text" label="Téléphone" />
                    <input-rule v-model="form.address" class="mt-5" :check-rules="canCheckInputs" rules="required" type="address" label="Adresse postal" />
                    <div class="text-center mt-3 py-2">
                        <button type="button" class="text-white bg-blue-600 hover:bg-blue-700 focus:outline-none rounded-xl px-7 py-4 text-center">
                            S'inscrire <i class="fas fa-check ml-1"></i>
                        </button>
                    </div>
                </div>
            </div>

        </sav-template>        
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {},
            canCheckInputs: false,
        }
    },
}
</script>
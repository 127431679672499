<template>
    <div>
        <carousel
        :per-page="1"
        :navigation-enabled="true"
        :pagination-enabled="false"
        @pageChange="changeAvisSlide"
        >
            <slot></slot>
        </carousel>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import { Carousel } from 'vue-carousel';

export default {
    components: {
        Carousel,
    },

    methods: {
        changeAvisSlide() {
            EventBus.$emit('close-slide-avis')
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <div class="container mx-auto">
            <h6 class="font-light text-lg text-center">{{$t('clients.title')}}</h6>
            <div class="w-full my-8">
                <!-- Using the slider component -->
                <!-- <slider ref="slider" :options="options">
                    <slideritem v-for="(brand, brandIndex) in brands" style="width: 20%" :key="'brand-' + brandIndex">
                        <img class="h-10" :src="brand.url" alt="brand logo">
                    </slideritem>
                    <div slot="loading">loading...</div>
                </slider> -->

                <!-- focusAt="center" -->
                <vue-glide
                v-if="displaySlider"
                :rewind="true"
                :per-view="nbLogo"
                :autoplay="true"
                :hoverpause="!0"
                :bound="!0"
                :animation-duration="3000"
                animationTimingFunc="linear"
                :rewindDuration="0"
                >
                    <vue-glide-slide class="" v-for="(brand, brandIndex) in endBrands" :key="'brand-' + brandIndex">
                        <img loading="lazy" class="h-10 mx-auto" :src="brand.url" :alt="'brand ' + brand.name">
                    </vue-glide-slide>
                </vue-glide>
            </div>
        </div>
    </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js'

export default {
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide
    },
    data() {
        return {
            displaySlider: false,
            nbLogo: 4,
            brands: [
                // Seb
                {name: 'Seb', url: '/img/seb.webp'},
                // geant casino
                {name: 'Geant casino', url: '/img/casino.png'},
                // Envie
                {name: 'Envie', url: '/img/envie.jpeg'},
                // Trax
                {name: 'Trax', url: '/img/trax.png'},
                // MarketMaker
                {name: 'MarketMaker', url: '/img/marketmaker.png'},
                // cora
                {name: 'Cora', url: '/img/cora.png'},
                // Reversio
                // {name: 'Revers.io', url: '/img/reversio.webp'},
                // mr bricolage
                {name: 'Mr bricolage', url: '/img/mr_bricolage.png'},
                // Arb
                {name: 'Arb', url: '/img/arb.png'},
                // Anrh
                {name: 'Anrh', url: '/img/anrh.png'},
                // Gt company
                {name: 'Gt Company', url: '/img/gt-company.png'},
                // Delonghi
                {name: 'Delonghi', url: '/img/delonghi.png'},
            ],
        }
    },

    computed: {
        endBrands() {
            let endData = []
            for (let i = 0; i < 20; i++) {
                this.brands.forEach((brand) => {
                    endData.push(brand)
                })
            }
            return endData
        }
    },

    mounted() {
        if (window.innerWidth < 500) {
            this.nbLogo = 2
        }
        this.displaySlider = true
    }
}
</script>

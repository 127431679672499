<template>
    <div>
        <sav-template :page-name="$t('repairer.titre')">
            <div class="2xl:w-2/3 lg:container mx-auto pt-10 mt-20">
                <div class="xl:w-2/3 lg:container mx-auto mt-5 mb-20 text-center">
                    <h1 class="text-5xl font-bold text-black text-black" v-html="$t('repairer.titre')"></h1>
                </div>

                <div id="menu" class="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 mt-10">
                    <div @click="scrollToSection(arg.id)" v-for="(arg, argIndex) in $t('repairer.args')" :key="'arg-' + argIndex" class="text-center mb-10 hover:underline duration-200 cursor-pointer" data-aos="fade-in" :data-aos-delay="argIndex < 8 ? argIndex * 50 : 0">
                        <img class="h-[100px] mb-2 mx-auto" :src="arg.img" alt="">
                        <h2 class="font-base text-black text-lg mt-4" v-html="arg.titre"></h2>
                    </div>
                </div>
            </div>

            <div class="2xl:w-2/3 lg:container mx-auto p-10 py-16 border rounded-3xl">
                <div class="font-black text-black text-3xl mb-8">Fonctionnalitées en +</div>
                <ul class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 list-disc ml-4">
                    <li class="mt-2" v-for="(item, itemIndex) in $t('repairer.features')" :key="'feature-item-' + itemIndex">
                        {{ item }}
                    </li>
                </ul>
            </div>

            <div id="clients-list" class="my-8 border-y pt-8 pb-5">
                <clients-list />
            </div>

            <div class="my-20">
                <div class="md:w-2/3 lg:w-1/2 px-5 md:px-0 mx-auto text-center">
                    <p class="text-xl font-light text-black mb-2.5" v-html="$t('repairer.intro')"></p>
                </div>
            </div>

            <div class="my-20">
                <div class="xl:w-2/3 lg:container mx-auto">
                    <arg-detail id="section-interconnexion" :data="$langTool.getObj('interconnexion', $t('repairer.args'))" side="left" />
                    <arg-detail id="section-livraison" class="mt-20" :data="$langTool.getObj('livraison', $t('repairer.args'))" side="right" />
                    <arg-detail id="section-planning_tournee" class="mt-20" :data="$langTool.getObj('planning_tournee', $t('repairer.args'))" side="left" />
                </div>
            </div>

            <stat-numbers custom-class="bg-gray-900 px-5 md:px-0 py-10" />

            <div class="my-20">
                <div class="xl:w-2/3 lg:container mx-auto">
                    <arg-detail id="section-facturation" class="mt-20" :data="$langTool.getObj('facturation', $t('repairer.args'))" side="left" />
                    <arg-detail id="section-commande_piece" class="mt-20" :data="$langTool.getObj('commande_piece', $t('repairer.args'))" side="right" />
                    <arg-detail id="section-workflows" class="mt-20" :data="$langTool.getObj('workflows', $t('repairer.args'))" side="left" />
                </div>
            </div>

            <div class="my-20 py-20 bg-gray-900 px-5 md:px-0">
                <div class="xl:w-2/3 lg:container mx-auto text-slate-100">
                    <h1 class="text-4xl" v-html="$t('repairer.seperateurs.1.titre')"></h1>
                    <p class="mt-4 text-lg" v-html="$t('repairer.seperateurs.1.description')"></p>
                </div>
            </div>

            <div class="my-20">
                <div class="xl:w-2/3 lg:container mx-auto">
                    <arg-detail id="section-logistique" class="mt-20" :data="$langTool.getObj('logistique', $t('repairer.args'))" side="left" />
                    <arg-detail id="section-communications" class="mt-20" :data="$langTool.getObj('communications', $t('repairer.args'))" side="right" />
                    <arg-detail id="section-bi" class="mt-20" :data="$langTool.getObj('bi', $t('repairer.args'))" side="left" />
                </div>
            </div>
            
            <div class="my-20 py-20 bg-gray-900 px-5 md:px-0">
                <div class="xl:w-2/3 lg:container mx-auto text-slate-100">
                    <h1 class="text-4xl" v-html="$t('repairer.seperateurs.2.titre')"></h1>
                    <p class="mt-4 text-lg" v-html="$t('repairer.seperateurs.2.description')"></p>
                </div>
            </div>

            <div class="my-20">
                <div class="xl:w-2/3 lg:container mx-auto">
                    <arg-detail id="section-inventaire" class="mt-20" :data="$langTool.getObj('inventaire', $t('repairer.args'))" side="left" />
                    <arg-detail id="section-app_mobile" class="mt-20" :data="$langTool.getObj('app_mobile', $t('repairer.args'))" side="right" />
                </div>
            </div>

            <div class="my-20 py-20 bg-gray-900 px-5 md:px-0">
                <div class="xl:w-2/3 lg:container mx-auto text-slate-100">
                    <h1 class="text-4xl" v-html="$t('repairer.seperateurs.3.titre')"></h1>
                    <p class="mt-4 text-lg" v-html="$t('repairer.seperateurs.3.description')"></p>
                </div>
            </div>

            <div class="xl:w-2/3 lg:container mx-auto">
                <h2 class="font-bold text-3xl text-center mb-10" v-html="$t('temoignage_titre')"></h2>
                <div class="border rounded-xl p-5 px-10">
                    <temoignages>
                        <slide>
                            <slide-avis ref="section-mrbricolage" :data="$langTool.getObj('mrbricolage', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-arb" :data="$langTool.getObj('arb', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-casino" :data="$langTool.getObj('casino', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-gtcompany" :data="$langTool.getObj('gtcompany', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-trax" :data="$langTool.getObj('trax', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-ageps" :data="$langTool.getObj('ageps', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-sologne" :data="$langTool.getObj('sologne', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-atr" :data="$langTool.getObj('atr', $t('temoignages'))" />
                        </slide>
                    </temoignages>
                </div>
            </div>

            <div class="xl:w-2/3 my-20 lg:container mx-auto">
                <ask-demo custom-class="rounded-xl" />
            </div>

            <div class="xl:w-2/3 mt-20 lg:container mx-auto">
                <faq :questions="$t('home.questions')"/>
            </div>

            <div class="overflow-x-hidden">
                <div v-if="displayScrollTopBtn" class="fixed bottom-10 right-10" data-aos="fade-left">
                    <button @click="scrollToTop" class="bg-white rounded-full px-3 py-1.5 shadow text-xl">
                        <i class="fas fa-chevron-up"></i>
                    </button>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
import Temoignages from '../components/Temoignages.vue';
import { Slide } from 'vue-carousel';
export default {
    components: {
        Temoignages,
        Slide,
    },
    data() {
        return {
            displayScrollTopBtn: false,
        }
    },
    computed: {
        citation() {
            return this.$langTool.getObj('max_1', this.$t('citations'))
        }
    },
    methods: {
        scrollToSection(id) {
            const divId = '#section-' + id + ' #title';
            
            const yOffset = (window.innerHeight / 2) - 100;
            const element = document.querySelector(divId);
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        },

        scrollToTop() {
            const divId = '#menu';
            
            const yOffset = 100;
            const element = document.querySelector(divId);
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    },
    mounted () {
        window.addEventListener('scroll', () => {
            this.displayScrollTopBtn = window.pageYOffset > 1000 ? true : false
        })

        var currentUrl = document.URL,
        urlParts   = currentUrl.split('#');   
        let anchor = (urlParts.length > 1) ? urlParts[1] : null;
        if (anchor) {
            let anchorSplits = anchor.split('section-')
            if (anchorSplits.length > 1) {
                anchorSplits.shift()
                let sectionId = anchorSplits.join('-')
                setTimeout(() => {
                    this.scrollToSection(sectionId)
                }, 500)
            }
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
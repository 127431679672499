<template>
    <div>
        <sav-template>
            <div class="mt-28">
                <h2 class="font-black text-black text-3xl text-center mb-10">Actualités</h2>
                <div class="flex justify-center">
                    <spinner v-if="loading" />
                </div>
                <div class="container mx-auto">
                    <div class="grid grid-cols-4 gap-5">
                        <div
                        v-for="(article, articleIndex) in articles"
                        :key="'article-' + articleIndex"
                        class="border rounded flex bg-white flex-col justify-between"
                        >
                            <div>
                                <div
                                class="w-full h-[200px] bg-cover bg-center rounded-t bg-slate-300"
                                :style="'background-image: url(' + (article.img ? article.img : '/img/no_image.jpeg') + ');'"
                                ></div>
                                <div class="p-4 pb-2">
                                    <h3 class="font-bold">{{ article.title }}</h3>
                                    <p class="font-light text-sm">{{ article.summary }}</p>
                                </div>
                            </div>
                            <div class="px-4 mb-4">
                                <a :href="'/blog/article/' + article.url" class="bg-emerald-600 hover:bg-emerald-700 duration-200 text-white px-4 py-2 rounded font-light text-sm">
                                    <i class="fas fa-eye mr-0.5"></i> Consulter
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'

export default {
    data() {
        return {
            articles: [],
            loading: false,
        };
    },
    methods: {
        async getArticles() {
            this.loading = true;
            let response = await apiService.post("vitrine.article.list", {
                no_user: 1,
                display: 1,
            });
            this.articles = response.data.posts.post;
            this.loading = false;
        }
    },
    mounted() {
        this.getArticles();
    },
}
</script>

<style lang="scss" scoped>

</style>
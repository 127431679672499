<template>
    <div>
        <!-- LABEL -->
        <label v-if="label" :for="uniqueId" class="block mb-2 text-sm font-medium" :class="errors.length > 0 ? 'text-red-500' : 'text-gray-900'">{{ label }}</label>

        <!-- TYPES -->
        <!-- Text -->
        <input v-if="type == 'text'" v-model="inpValue" type="text" :id="uniqueId" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none" :class="errors.length > 0 ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900'" />

        <!-- Date -->
        <input v-if="type == 'date'" v-model="inpValue" type="date" :id="uniqueId" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none" :class="errors.length > 0 ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900'" />

        <!-- Select -->
        <template v-if="type == 'select'">
            <select v-if="options && options.values" v-model="inpValue" :id="uniqueId" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5 focus:outline-none" :class="errors.length > 0 ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900'">
                <option v-for="(selectItem, selectItemIndex) in options.values" :key="uniqueId + '-select-' + selectItemIndex" :value="options.key ? selectItem[options.key] : selectItem">
                    {{ options.label ? selectItem[options.label] : selectItem }}
                </option>
            </select>
        </template>

        <template v-if="type == 'textarea'">
            <textarea rows="5" v-model="inpValue" id="uniqueId" class="bg-gray-50 text-sm rounded-lg block w-full p-2.5" :class="errors.length > 0 ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900'"></textarea>
        </template>

        <!-- Address -->
        <!-- <address-input v-if="type == 'address'" :default-value="options && options.defaultValue" class="rounded-xl" :class="errors.length > 0 ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900'" @get="emitAddress" /> -->

        <!-- ERRORS -->
        <ul class="text-xs list-disc ml-3 mt-2 text-red-500 font-light">
            <li v-for="(error, errorIndex) in errors" :key="uniqueId + '-error-' + errorIndex">
                {{ error }}
            </li>
        </ul>
    </div>
</template>

<script>
// import AddressInput from '@/components/AddressInput'
export default {
    // components: {
    //     AddressInput,
    // },

    props: {
        type: {required: true},
        label: {},
        value: {},
        rules: {},
        options: {},
        checkRules: {default: true}
    },

    watch: {
        // value: {
        //     deep: true,
        //     handler: function(value) {
        //         this.inpValue = value
        //     }
        // },

        checkRules: function(value) {
            if (value || this.hasFirstCheck) {
                this.rulesValidated()
                this.emitData()
            }
        },

        inpValue: {
            deep: true,
            handler: function() {
                if (this.checkRules || this.hasFirstCheck) {
                    this.rulesValidated()
                }
                this.emitData()
            }
        }
    },

    data() {
        return {
            inpValue: null,
            errors: [],
            hasFirstCheck: false
        }
    },

    computed: {
        uniqueId() {
            return 'sav-input-' + this._uid
        }
    },

    methods:{
        emitData() {
            this.$emit('input', {
                valid: this.errors.length > 0 ? false : true,
                value: this.inpValue
            })
        },

        rulesValidated() {
            this.hasFirstCheck = true
            this.errors = []
            if (this.rules) {
                const rules = this.rules.split('|')
                const rulesTools = {
                    required: () => {
                        if (!this.inpValue) {
                            this.errors.push('Champ obligatoire')
                        }
                    },

                    tel: () => {
                        var regex = new RegExp(/^(01|02|03|04|05|06|07)[0-9]{8}/gi);
                        if (this.inpValue && !regex.test(this.inpValue)) {
                            this.errors.push('Numéro invalide')
                        }
                    },

                    num: () => {
                        if (this.inpValue && isNaN(this.inpValue)) {
                            this.errors.push('Le champ doit être un chiffre')
                        }
                    },

                    min: (nb) => {
                        if (this.inpValue && Number(this.inpValue) < nb) {
                            this.errors.push('Minimum: ' + nb)
                        }
                    },

                    email: () => {
                        if (this.inpValue && !String(this.inpValue).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                            this.errors.push('Email invalide')
                        }
                    }
                }

                rules.forEach(rule => {
                    if (rule.split(':')[0] == 'min') {
                        rulesTools[rule.split(':')[0]](rule.split(':')[1])
                    } else {
                        rulesTools[rule]()
                    }
                });
            }
            return (this.errors.length > 0) ? false : true
        },

        emitAddress(address) {
            this.inpValue = address
            this.$emit('get', address)
        }
    },

    mounted() {
        this.inpValue = this.value
        if (this.checkRules) {
            this.rulesValidated()
        }
    }
}
</script>

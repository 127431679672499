<template>
    <div>
        <!-- Header -->
        <div class="hidden md:block py-5 fixed border-b border-slate-200 top-0 w-full backdrop-blur-sm bg-white/90 bg-opacity-80" style="z-index: 999">
            <div class="md:flex items-center justify-between px-5 lg:px-0 lg:container mx-auto">
                <div class="flex items-center gap-14">
                    <div>
                        <h2 class="text-2xl font-semibold text-black">Districloud</h2>
                        <p class="text-xs font-light" v-html="$t('template.header.powered')"></p>
                    </div>
                    <ul class="flex items-center gap-8 font-light text-lg text-gray-400">
                        <!-- Home -->
                        <a href="/">
                            <li :class="isSelected(['home']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">{{ $t('template.header.accueil') }}</li>
                        </a>

                        <!-- Services -->
                        <li>
                            <dropdown-menu
                            v-model="displaySolutions"
                            :hover="true"
                            :hover_time="50"
                            :right="false"
                            >
                                <button id="services-btn dropdown-toggle" :class="isSelected(['selfcare', 'shop', 'supplier', 'repairer', 'insurer']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">
                                    {{ $t('template.header.solutions') }} <i class="fas fa-chevron-down ml-1 text-sm"></i>
                                </button>
                                <div slot="dropdown" class="fixed bg-white rounded-xl mt-5 shadow p-8 text-slate-800">
                                    <div class="font-bold uppercase mb-2 text-sm" v-html="$t('template.header.who')"></div>
                                    <ul class="mt-3 ml-4">
                                        <li
                                        class="cursor-pointer mb-2 bullet-emerald"
                                        v-for="(item, itemIndex) in subMenuItems"
                                        :key="'menu-item-' + itemIndex"
                                        >
                                            <a :href="item.url" v-html="item.label" :target="item.target"></a>
                                        </li>
                                    </ul>
                                </div>
                            </dropdown-menu>
                        </li>

                        <!-- pricing -->
                        <!-- <a href="/tarifs">
                            <li :class="isSelected(['pricing']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">{{ $t('template.header.pricing') }}</li>
                        </a> -->

                        <!-- about -->
                        <a href="/a-propos">
                            <li :class="isSelected(['about']) ? 'text-emerald-600 font-bold' : 'hover:text-emerald-600 duration-200 cursor-pointer'">{{ $t('template.header.about') }}</li>
                        </a>
                    </ul>
                </div>

                <div class="flex items-center gap-3">                    
                    <!-- Connect -->
                    <a href="http://districloud.net" class="bg-slate-200 hover:bg-slate-300 px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.connect')"></a>
                    <a href="http://distriadmin.savlog.net/inscription" class="bg-slate-200 hover:bg-slate-300 px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.register')"></a>

                    <!-- Contact button -->
                    <a href="/contact" class="hidden lg:block bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.ask_demo')"></a>
                    <a href="/contact" class="lg:hidden bg-emerald-600 hover:bg-emerald-700 text-white px-4 py-3 rounded-full font-bold text-sm" v-html="$t('template.header.demo')"></a>


                    <!-- Translate button -->
                    <div class="flex items-center gap-2">
                        <i class="fa-solid fa-globe"></i>
                        <dropdown-menu
                        v-model="displayLanguages"
                        :hover="true"
                        :hover_time="50"
                        :right="false"
                        >
                            <button id="services-btn dropdown-toggle" class="hover:text-emerald-600 duration-200 cursor-pointer'">
                                {{ $t('template.header.langue') }} <i class="text-xs fas fa-chevron-down ml-1"></i>
                            </button>
                            <div slot="dropdown" class="bg-white py-1 px-2 fixed rounded-xl text-slate-800 -ml-5">
                                <ul>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('fr')">
                                        <img class="h-3 w-5" src="/img/fr_flag.png" alt="fr flag"> FR
                                    </li>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('en')">
                                        <img class="h-3 w-5" src="/img/en_flag.png" alt="en flag"> EN
                                    </li>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('es')">
                                        <img class="h-3 w-5" src="/img/es_flag.png" alt="es flag"> ES
                                    </li>
                                    <li class="flex items-center gap-2 cursor-pointer text-center mt-1 hover:text-emerald-600" @click="translateTo('it')">
                                        <img class="h-3 w-5" src="/img/it_flag.png" alt="it flag"> IT
                                    </li>
                                </ul>
                            </div>
                        </dropdown-menu>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile button -->
        <div class="bg-white fixed top-0 left-0 right-0 p-2 px-5 bg-opacity-80 bg-blur flex justify-end">
            <button @click="displayMobileMenu = true">
                <i class="fas fa-bars text-3xl text-gray-500"></i>
            </button>
        </div>
        <!-- Mobile menu -->
        <div v-if="displayMobileMenu">
            <div @click="displayMobileMenu = false" class="z-20 bg-black bg-opacity-70 fixed top-0 left-0 right-0 bottom-0"></div>
            <div data-aos="slide-right" data-aos-delay="100" class="bg-white fixed top-0 bottom-0 left-0 right-20 p-10 z-20 overflow-y-auto">
                <div class="flex items-center justify-between">
                    <div>
                        <h2 class="text-xl font-bold">Districloud</h2>
                        <p class="text-xs font-light" v-html="$t('template.header.powered')"></p>
                        <h2 class="font-base text-lg text-emerald-600">{{ pageName }}</h2>
                    </div>
                    <button @click="displayMobileMenu = false" class="bg-red-500 text-white rounded-xl p-2 px-4"><i class="fas fa-times"></i></button>
                </div>
                
                <div class="my-8">
                    <a href="/">
                        <div class="text-2xl">{{ $t('template.header.accueil') }}</div>
                    </a>
                </div>

                <div class="my-8">
                    <h3 class="text-2xl">{{ $t('template.header.solutions') }}</h3>
                    <ul class="pl-5 mt-3">
                        <a :href="item.url" v-for="(item, itemIndex) in subMenuItems" :key="'menu-item-' + itemIndex">
                            <li
                            class="cursor-pointer mb-2 bullet-emerald"
                            v-html="item.label"
                            >
                            </li>
                        </a>
                    </ul>
                </div>
                <!-- <div class="my-8">
                    <a href="/tarifs">
                        <div class="text-2xl">{{ $t('template.header.pricing') }}</div>
                    </a>
                </div> -->
                <div class="my-8">
                    <a href="/a-propos">
                        <div class="text-2xl">{{ $t('template.header.about') }}</div>
                    </a>
                </div>
                <div class="my-8">
                    <a href="/contact">
                        <div class="text-2xl">{{ $t('template.header.contact') }}</div>
                    </a>
                </div>
                <div class="my-8">
                    <a href="http://distriadmin.savlog.net/inscription">
                        <div class="text-2xl">{{ $t('template.header.register') }}</div>
                    </a>
                </div>
                <div class="my-8">
                    <dropdown-menu
                    v-model="displayLanguages"
                    :hover="true"
                    :hover_time="50"
                    :right="false"
                    >
                        <button id="services-btn dropdown-toggle" class="text-sm hover:text-emerald-600 duration-200 cursor-pointer'">
                            {{ $t('template.header.langue') }} <i class="text-xs fas fa-chevron-down ml-1"></i>
                        </button>
                        <div slot="dropdown" class="bg-white fixed rounded-xl text-slate-800">
                            <ul>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('fr')">FR</li>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('en')">EN</li>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('es')">ES</li>
                                <li class="cursor-pointer text-sm text-center mt-1 hover:text-emerald-600" @click="translateTo('it')">IT</li>
                            </ul>
                        </div>
                    </dropdown-menu>
                </div>
            </div>
        </div>
        

        <!-- Website content -->
        <div class="min-h-screen">
            <slot></slot>
        </div>

        <!-- Button scroll top -->
        <button @click="scrollToTop" :class="classBtnScrollTop" class="bg-emerald-600 hover:bg-emerald-700 duration-200 cursor-pointer p-2 px-3 rounded-full text-white fixed bottom-8 right-8">
            <i class="fas fa-chevron-up"></i>
        </button>

        <!-- Footer -->
        <div class="pt-20 pb-10">
            <div class="container mx-auto px-4 lg:px-0">
                <div class="lg:flex items-center justify-between gap-20">
                    <div class="text-center lg:text-left lg:w-1/3 xl:w-2/4">
                        <span class="text-5xl font-black">Districloud</span>
                        <p class="text-xl font-extralight mt-2 lg:pr-20 mb-3">{{ $t('template.footer.subtitle') }}</p>
                    </div>
                    <div v-if="$route.name != 'contact'" @click="$router.push({name: 'contact'})" class="text-center bg-emerald-700 hover:bg-emerald-600 cursor-pointer duration-200 hover:translate-y-1 flex items-center justify-center p-5 py-8 rounded-xl text-white">
                        <i class="fas fa-envelope text-5xl"></i>
                        <div class="ml-5 text-left">
                            <div class="text-xl font-bold">{{ $t('template.footer.email') }}</div>
                        </div>
                    </div>
                </div>
    
                <div class="text-center mt-20 font-extralight text-xs">
                    <div>© 1998 - 2023 <a class="underline" target="_blank" href="http://www.savlog.net">SAVLOG SAS</a> {{ $t('template.footer.capital') }} | All Rights Reserved</div>
                    <div>Connexion au logiciel: <a class="underline" target="_blank" href="https://districloud.net">distrcloud.net</a></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu'
export default {
    components: {DropdownMenu},

    props: {
        pageName: {}
    },

    watch: {
        displaySolutions(value) {
            if (!value) {
                this.selectedMenu = 'districloud'
            }
        }
    },

    data() {
        return {
            displayMobileMenu: false,
            displaySolutions: false,
            displayLanguages: false,

            selectedMenu: 'districloud',

            classBtnScrollTop: 'hidden',
        }
    },

    computed: {
        subMenuItems() {
            return [
                // "districloud": {
                //     title: 'Districloud',
                //     items: [
                //         {label: "Fournisseur", route: 'services.supplier'},
                //         {label: "Distributeur", route: 'services.shop'},
                //         {label: "Réparateur", route: null},
                //     ],
                // },
                // "savlogsta": {
                //     title: 'Savlog STA',
                //     items: [
                //         {label: "Centre de réparation", route: 'services.repairStation'},
                //         {label: "Api", route: null},
                //         {label: "Auto-entrepreneur", route: null},
                //     ],
                // },
                // "selfcare": {
                //     title: 'Selfcare',
                //     items: [
                //         {label: "Distributeur", route: 'services.selfcare'},
                //         {label: "Fournisseur", route: 'services.selfcare'},
                //         {label: "Particulier", route: 'services.selfcare'},
                //     ],
                // },
                // "mobilite": {
                //     title: 'Mobilité',
                //     items: [
                //         {label: "Centre technique", route: null},
                //         {label: "Auto-entrepreneur", route: null},
                //     ],
                // },
                // "interconnect": {
                //     title: 'Interconnexion',
                //     items: [
                //         {label: "En savoir plus", route: null},
                //         {label: "Documentation développeur", route: null},
                //     ],
                // },
                // "all": {
                //     title: 'Toutes les solutions',
                //     items: [
                //         {label: "Toutes nos solutions", route: 'services.solutions'},
                //     ]
                // }
                {
                    "url": "/fournisseur",
                    "label": this.$t('supplier.titre')
                },
                {
                    "url": "/distributeur",
                    "label": this.$t('shop.titre')
                },
                {
                    "url": "/reparateur",
                    "label": this.$t('repairer.titre')
                },
                {
                    "url": "/assureur",
                    "label": this.$t('insurer.titre')
                },
                {
                    "url": "https://www.districloud.net/dev/cases_api.html",
                    "label": this.$t('developer.titre'),
                    "target": "_blank"
                },
            ]
        }
    },

    methods: {
        isSelected(names) {
            return names.includes(this.$route.name)
        },

        translateTo(lang) {
            localStorage.setItem('lang', lang)
            this.$i18n.locale = lang
        },

        scrollToTop() {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }
    },

    mounted () {
        if (localStorage.getItem('lang')) {
            this.$i18n.locale = localStorage.getItem('lang')
        }

        window.addEventListener("scroll", () => {
            if (this.classBtnScrollTop != 'hidden' && window.scrollY < 1000) {
                this.classBtnScrollTop = 'hidden'
            }
            if (this.classBtnScrollTop != '' && window.scrollY >= 1000) {
                this.classBtnScrollTop = ''
            }
        })
    },
}
</script>
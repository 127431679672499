import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

/* --------------
    LIBS
---------------*/

// Tailwind
// import './assets/tailwind.css'

// Animation
import AOS from 'aos'

// Glide slider
import 'vue-glide-js/dist/vue-glide.css'


/* --------------
    PACKAGE
---------------*/
// Vue number
import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

// Toastification
import Toast from "vue-toastification";
Vue.use(Toast, {});

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// Modal
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

const i18n = new VueI18n({
    locale: 'fr',
    messages: {
        fr: require('./lang/fr.json'),
        en: require('./lang/en.json'),
        it: require('./lang/it.json'),
        es: require('./lang/es.json'),
    },
    silentTranslationWarn: true
}) 

/* --------------
    COMPONENTS
---------------*/
Vue.component('sav-template', require('./components/SavTemplate').default)
Vue.component('clients-list', require('./components/ClientsList').default)
Vue.component('stat-numbers', require('./components/StatNumbers').default)
Vue.component('secure-data', require('./components/SecureData').default)
Vue.component('international', require('./components/International').default)
Vue.component('ask-demo', require('./components/AskDemo').default)
Vue.component('input-rule', require('./components/InputRule').default)
Vue.component('faq', require('./components/Faq').default)
Vue.component('arg-detail', require('./components/ArgDetail').default)
Vue.component('slide-avis', require('./components/SlideAvis').default)
Vue.component('blog-articles', require('./components/BlogArticles').default)
Vue.component('spinner', require('./components/Spinner').default)

/* --------------
    TOOLS
---------------*/
import LangTool from './tools/LangTool'
Vue.prototype.$langTool = LangTool


/* --------------
    INIT APP
---------------*/
new Vue({
    i18n,
    router,
    created () {
        AOS.init()
    },
    render: h => h(App)
}).$mount('#app')

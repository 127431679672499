<template>
    <div :id="'avis-' + _uid" class="px-2">
        <div class="md:p-5 lg:p-10 text-center">
            <img class="h-10 mb-4 mx-auto" :src="data.img" :alt="data.societe">
            <p class="text-xl text-black" :class="expand ? 'text-left' : 'text-center'" v-html="expand ? data.contenu : shortenDesc.text"></p>
            <button @click="expand = !expand" v-if="shortenDesc.cutted" class="mt-2 text-blue-500 underline">
                <template v-if="expand">Voir moins</template>
                <template v-if="!expand">Voir plus</template>
            </button>
            <div class="border-t mt-5 pt-5 gap-5">
                <div>
                    <div class="font-light text-base">
                        <span class="font-bold text-black" v-html="data.author"></span>
                        <span v-if="data.author && data.statut"> - </span>
                        <span :class="data.author ? '' : 'font-bold text-black'" v-html="data.statut"></span>
                    </div>
                    <p class="text-sm mt-1 font-thin" v-html="data.societe_description"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus';

export default {
    props: {
        data: {}
    },

    watch: {
        expand(value) {
            if (!value) {
                var element = document.getElementById('avis-' + this._uid);
                element.scrollIntoView();
            }
        }
    },

    data() {
        return {
            expand: false
        }
    },

    computed: {
        shortenDesc() {
            let maxWords = 50
            let splited = this.data.contenu.split(' ')
            let reduceSplited = splited.slice(0, maxWords)
            return {
                cutted: splited.length > maxWords,
                text: reduceSplited.join(' ')
            }
        }
    },

    mounted() {
        EventBus.$on('close-slide-avis', () => {
            this.expand = false
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
import myaxios from './axios.service'

function convertObjToFormData(keyname, obj)
{
    var formData = new FormData();

    for (var key in obj) {
        formData.append(key, obj[key]);
    }

    formData.append('operation', keyname)

    if (localStorage['auth']) {
        formData.append('id', 32)
        formData.append('token', '1747988f64386d614f95fa7842lkj6c9')
    }

    return formData
}

const ApiService = {
    post: async function (keyname, obj, options = null) {
        return await myaxios.post('', convertObjToFormData(keyname, obj), options)
    },
}
export default ApiService

<template>
    <div>
        <sav-template>
            <div class="2xl:w-2/3 lg:container mx-auto h-screen flex items-center">
                <div class="text-center">
                    <h1 class="text-6xl font-bold" v-html="$t('about.banner.titre')"></h1>
                    <p class="text-3xl mt-4 font-extralight" v-html="$t('about.banner.sous_titre')"></p>
                </div>
            </div>
            <div class="flex justify-center">
                <div :class="bounceAnimation ? 'animate__bounce' : ''" class="hidden md:block pb-10 -mt-20 text-xl animate__animated">
                    <button class="cursor-pointer" @click="scrollToClients">
                        <i class="fas fa-chevron-down"></i>
                    </button>
                </div>
            </div>

            <!-- Clients -->
            <div id="clients-list" class="border-y pt-8 pb-5">
                <clients-list />
            </div>

            <div class="mt-20">
                <div class="text-center text-4xl font-bold px-3 lg:px-0 lg:w-1/2 mx-auto" v-html="$t('about.temoignage.titre')"></div>
                
                <div class="lg:container mx-auto mt-20">
                    <div class="grid lg:grid-cols-2 items-center">
                        <div>
                            <div
                            class="rounded-full mx-auto shadow shadow-lg shadow-slate-500"
                            style="
                                background-image: url('https://i.ibb.co/F5mWx95/1517738702953.jpg');
                                background-size: cover;
                                background-position: center center;
                                width: 170px;
                                height: 170px;
                            "
                            ></div>
                        </div>
                        <div>
                            <div class="bg-slate-50 p-10 py-16 rounded-xl border-2 mt-5 lg:mt-0 mb-5" data-aos="fade-up">
                                <p v-for="(item, itemIndex) in $t('about.temoignage.philippe')" :key="'philippe-' + itemIndex" class="mb-4 font-light text-lg" v-html="item"></p>
                                <div class="flex items-center mt-5">
                                    <div>
                                        <div class="font-bold text-lg">Philippe C.</div>
                                        <div class="font-light">Le créateur et spécialiste du SAV</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid lg:grid-cols-2 items-center mb-5">
                        <div
                        class="lg:hidden rounded-full mx-auto shadow shadow-lg shadow-slate-500"
                        style="
                            background-image: url('https://disticloudfiles.net/upload/files/fe34f002a0425785127c3ee423eec476.jpeg');
                            background-size: cover;
                            background-position: center center;
                            width: 170px;
                            height: 170px;
                        "
                        ></div>
                        <div>
                            <div class="bg-slate-50 p-10 py-16 mt-5 lg:m-t0 rounded-xl border-2 mb-5" data-aos="fade-up">
                                <p v-for="(item, itemIndex) in $t('about.temoignage.max')" :key="'max-' + itemIndex" class="mb-4 font-light text-lg" v-html="item"></p>
                                <div class="flex items-center mt-5">
                                    <div>
                                        <div class="font-bold text-lg">Maxime Large</div>
                                        <div class="font-light">L’expert du développement</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                            class="hidden lg:block rounded-full mx-auto shadow shadow-lg shadow-slate-500"
                            style="
                                background-image: url('https://disticloudfiles.net/upload/files/fe34f002a0425785127c3ee423eec476.jpeg');
                                background-size: cover;
                                background-position: center center;
                                width: 170px;
                                height: 170px;
                            "
                            ></div>
                        </div>
                    </div>
                    <div class="grid lg:grid-cols-2 items-center mb-5">
                        <div>
                            <div
                            class="rounded-full mx-auto shadow shadow-lg shadow-slate-500"
                            style="
                                background-image: url('https://disticloudfiles.net/upload/files/e0e1d97495b5bd356fcfa4034252cb0e.jpeg');
                                background-size: cover;
                                background-position: center center;
                                width: 170px;
                                height: 170px;
                            "
                            ></div>
                        </div>
                        <div>
                            <div class="bg-slate-50 p-10 py-16 rounded-xl border-2 mt-5 lg:mt-0 mb-5" data-aos="fade-up">
                                <p v-for="(item, itemIndex) in $t('about.temoignage.victor')" :key="'victor-' + itemIndex" class="mb-4 font-light text-lg" v-html="item"></p>
                                <div class="flex items-center mt-5">
                                    <div>
                                        <div class="font-bold text-lg">Victor Anweiler</div>
                                        <div class="font-light">Le jeune créatif</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-28">
                <div class="text-center text-4xl font-bold w-1/2 mx-auto mb-20">Nos valeurs</div>
                
                <div class="2xl:w-2/3 lg:container mx-auto">
                    <div class="grid lg:grid-cols-2 gap-5">
                        <div class="hidden lg:block w-full rounded-xl" style="
                        background-image: url('https://images.unsplash.com/photo-1535957998253-26ae1ef29506?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=736&q=80');
                        background-size: cover;
                        background-position: center center;
                        "></div>
                        <div>
                            <div class="p-10 py-16 border border-emerald-100 rounded-3xl bg-emerald-50 text-emerald-900 mb-5" data-aos="fade-up">
                                <h2 class="text-bold text-xl font-bold" v-html="$t('about.valeurs.satisfaction.titre')"></h2>
                                <ul class="mt-4 ml-4">
                                    <li v-for="(item, itemIndex) in $t('about.valeurs.satisfaction.items')" :key="'satisfaction-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                </ul>
                            </div>
                            <div class="p-10 py-16 border border-emerald-100 rounded-3xl bg-emerald-50 text-emerald-900 mb-5" data-aos="fade-up">
                                <h2 class="text-bold text-xl font-bold" v-html="$t('about.valeurs.inventivite.titre')"></h2>
                                <ul class="mt-4 ml-4">
                                    <li v-for="(item, itemIndex) in $t('about.valeurs.inventivite.items')" :key="'inventivite-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                </ul>
                            </div>
                            <div class="p-10 py-16 border border-emerald-100 rounded-3xl bg-emerald-50 text-emerald-900 mb-5" data-aos="fade-up">
                                <h2 class="text-bold text-xl font-bold" v-html="$t('about.valeurs.humeur.titre')"></h2>
                                <ul class="mt-4 ml-4">
                                    <li v-for="(item, itemIndex) in $t('about.valeurs.humeur.items')" :key="'humeur-' + itemIndex" class="bullet-emerald" v-html="item"></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-20 mb-10 lg:mb-0">
                <div class="grid lg:grid-cols-2 gap-10 items-center">
                    <img class="lg:rounded-r-xl" src="https://www.start-way.com/wp-content/uploads/2022/03/Startway-Coworking-Limonest-Entree-principale-reception.jpg" alt="startway limonest">
                    <div data-aos="fade-up">
                        <div class="text-4xl font-black mb-5" v-html="$t('about.bureaux.titre')"></div>
                        <p class="text-2xl font-light" v-html="$t('about.bureaux.description')"></p>
                        <p class="text-xl font-thin mt-5"> <span v-html="$t('about.bureaux.adresse')"></span> <a href="https://goo.gl/maps/rqiATeNdLCfFLMj29" target="_blank" class="font-bold underline">81 Rue Sans Souci, 69760 Limonest.</a></p>
                    </div>
                </div>
            </div>

            <ask-demo class="md:mt-20" />
        </sav-template>        
    </div>
</template>
<script>
export default {
    created () {
        document.title = "Districloud - Des spécialistes SAV pour concevoir une plateforme unique "

        // Mata og
        var link = document.createElement('meta');
        link.setAttribute('property', 'og:url');
        link.content = document.location
        document.getElementsByTagName('head')[0].appendChild(link)

        // Meta description
        link = document.createElement('meta');
        link.setAttribute('name', 'description');
        link.content = 'Districloud: Des professionnels des métiers du service après vente. Des développeurs d\'outils sur-mesure, personnalisables et évolutifs. Des spécialistes du SAV à l\'écoute.'
        document.getElementsByTagName('head')[0].appendChild(link)

        link = document.createElement('meta');
        link.setAttribute('name', 'keywords');
        link.content = "Districloud,Service après vente,Plateforme collaborative,Gestion du service client,Gestion des demandes de SAV,Procédures de SAV,Réparations,Retours produits,Pièces détachées,Données d'analyse,Gestion de la D3E,Call center,Fournisseur,Distributeur,Réparateur,Consommateur,Relation clients,Logistique,Gestion quotidienne,Service inédit,RSE (Responsabilité Sociétale des Entreprises),Transports optimisés,Numérisation des dossiers,Normes RGPD (Règlement général sur la protection des données),Hébergement des données en Europe,Logiciel SaaS,Gestion du SAV depuis 1998"
        document.getElementsByTagName('head')[0].appendChild(link)
    },

    data() {
        return {
            bounceAnimation: false,
        }
    },

    methods: {
        scrollToClients() {
            var elmntToView = document.getElementById("clients-list");
            elmntToView.scrollIntoView({behavior: "smooth"}); 
        }
    },

    mounted() {
        setTimeout(() => {
            this.bounceAnimation = true
            setInterval(() => {
                this.bounceAnimation = !this.bounceAnimation
            }, 3000)
        }, 3000)
    }
}
</script>
<template>
    <div>
        <sav-template :page-name="$t('insurer.titre')">
            <div class="2xl:w-2/3 lg:container mx-auto pt-10 mt-20">
                <div class="xl:w-2/3 lg:container mx-auto mt-5 text-center">
                    <h1 class="text-5xl font-bold text-black text-black" v-html="$t('insurer.titre')"></h1>
                </div>
            </div>

            <div class="mt-10 mb-20 md:w-2/3 lg:w-1/2 px-5 md:px-0 mx-auto text-center">
                <p class="text-xl font-light text-black mb-2.5" v-html="$t('insurer.intro')"></p>
            </div>

            <div id="clients-list" class="mt-8 border-y pt-8 pb-5">
                <clients-list />
            </div>

            <stat-numbers custom-class="bg-gray-900 px-5 md:px-0 py-10 mb-10" />

            <div class="xl:w-2/3 lg:container mx-auto">
                <h2 class="font-bold text-3xl text-center mb-10" v-html="$t('temoignage_titre')"></h2>
                <div class="border rounded-xl p-5 px-10">
                    <temoignages>
                        <slide>
                            <slide-avis ref="section-mrbricolage" :data="$langTool.getObj('mrbricolage', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-arb" :data="$langTool.getObj('arb', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-casino" :data="$langTool.getObj('casino', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-gtcompany" :data="$langTool.getObj('gtcompany', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-trax" :data="$langTool.getObj('trax', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-ageps" :data="$langTool.getObj('ageps', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-sologne" :data="$langTool.getObj('sologne', $t('temoignages'))" />
                        </slide>
                        <slide>
                            <slide-avis ref="section-atr" :data="$langTool.getObj('atr', $t('temoignages'))" />
                        </slide>
                    </temoignages>
                </div>
            </div>

            <div class="xl:w-2/3 my-20 lg:container mx-auto">
                <ask-demo custom-class="rounded-xl" />
            </div>

            <div class="xl:w-2/3 mt-20 lg:container mx-auto">
                <faq :questions="$t('home.questions')"/>
            </div>

            <div class="overflow-x-hidden">
                <div v-if="displayScrollTopBtn" class="fixed bottom-10 right-10" data-aos="fade-left">
                    <button @click="scrollToTop" class="bg-white rounded-full px-3 py-1.5 shadow text-xl">
                        <i class="fas fa-chevron-up"></i>
                    </button>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
import Temoignages from '../components/Temoignages.vue';
import { Slide } from 'vue-carousel';

export default {
    components: {
        Temoignages,
        Slide,
    },
    data() {
        return {
            displayScrollTopBtn: false,
        }
    },
    computed: {
        citation() {
            return this.$langTool.getObj('max_1', this.$t('citations'))
        }
    },
    methods: {
        scrollToSection(id) {
            const divId = '#section-' + id + ' #title';
            
            const yOffset = (window.innerHeight / 2) - 100;
            const element = document.querySelector(divId);
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        },

        scrollToTop() {
            const divId = '#menu';
            
            const yOffset = 100;
            const element = document.querySelector(divId);
            const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    },
    mounted () {
        window.addEventListener('scroll', () => {
            this.displayScrollTopBtn = window.pageYOffset > 1000 ? true : false
        })
    },
}
</script>

<style lang="scss" scoped>

</style>